
import MessagesComponent from '@/components/Messages.vue'
import { ArticleService, CampaignService } from '@/services'
import { Campaign } from '@/types'
import { Article, IArticle } from '@/types/article'
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'EntitesArticleView',
})
export default class EntitesArticleView extends Vue {
  loadingCampaigns = false
  campaigns: Campaign[] = []

  loadingArticles = false
  articles: Article[] = []

  selectedCampaign: Campaign | null = null

  sortBy = 'productID'
  sortReverse = false

  async mounted(): Promise<void> {
    console.log('EntitesArticleView mounted...')
    this.campaigns = await this.fetchCampaigns()
  }

  async reloadArticles(ev: Event | null = null): Promise<void> {
    ev?.preventDefault()
    if (this.selectedCampaign != null) {
      this.articles = await ArticleService.fetchArticles(this.selectedCampaign.id)
    }
  }

  async fetchArticles(campaignId: number): Promise<IArticle[]> {
    this.loadingArticles = true
    try {
      return ArticleService.fetchArticles(campaignId)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.loadingArticles = false
    }
    return []
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    this.loadingCampaigns = true
    try {
      const campaigns = await CampaignService.fetchCampaigns()
      if (campaigns.length > 0) {
        this.selectedCampaign = campaigns[0]
        this.articles = await ArticleService.fetchArticles(this.selectedCampaign.id)
      }
      return campaigns
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
    } finally {
      this.loadingCampaigns = false
    }
    return []
  }

  sort(columnName: string): void {
    if (columnName === this.sortBy) {
      this.sortReverse = !this.sortReverse
    } else {
      this.sortBy = columnName
      this.sortReverse = false
    }

    this.articles = this.articles.sort((a: IArticle, b: IArticle) => {
      const modifier = this.sortReverse ? -1 : 1

      switch (this.sortBy) {
        case 'productID':
          return a.productID.localeCompare(b.productID, 'sv-SE') * modifier
        case 'name':
          return a.name.localeCompare(b.name, 'sv-SE') * modifier
        case 'campaignName':
          return a.campaign.name.localeCompare(a.campaign.name, 'sv-SE') * modifier
      }

      return 0
    })
  }
}
